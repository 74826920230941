import React, { useState } from "react";
import { PageLayout, ArticleList } from "@components";
import { graphql } from "gatsby";
import * as styles from "./caseStudies.module.scss";
import { CmsNode } from "@components/CMS/CmsTypes";
import CaseHero from "./components/CaseHero";
import Margins from "@components/Margins/Margins";
import TrustedByV2 from "@components/TrustedByV2/TrustedByV2";
import { JoinInstitutionsV2 } from "../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import CaseTestimonials from "./components/CaseTestimonials";
import { StaticImage } from "gatsby-plugin-image";

type CaseStudyProps = {
	data: {
		caseStudies: {
			nodes: CmsNode[];
		};
	};
};

export default function ResourcesCaseStudiesPage({ data }: CaseStudyProps) {
	const [displayNum, setDisplayNum] = useState(5);

	const { caseStudies } = data;

	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "case-studies" }}
		>
			<Margins className={styles.margin}>
				<CaseHero 
				title="Case studies"
				subtitle="Discover how thousands of Scientists are using Genemod to drive their efficiency in lab management."
				image={<StaticImage src="./assets/cashero.png" alt="case-studies-hero" className={styles.hero_image}/> }
				tabletImage={<StaticImage src="./assets/herotablet.png" alt="case-studies-hero" className={styles.hero_image}/> }
				/>
				<div className={styles.faqbg}>
					<div className={styles.roibg}>
						<ArticleList
							data={caseStudies?.nodes}
							listTitle="Recent case studies"
							type="studies"
							displayNum={displayNum}
							setDisplayNum={setDisplayNum}
						/>
					</div>
				</div>
				<div
					style={{
						width: "100%",
						maxWidth: "1400px",
						margin: "auto",
					}}
				>
					<TrustedByV2
						trustedByContainerClass={styles.trustedByContainerClass}
						logosContainerClass={styles.logosContainerClass}
						logoTabletClass={styles.logoTabletClass}
						logoClass={styles.logoClass}
						titleMobileVariant="HEADING_4_V2"
					/>
				</div>
				<div className={styles.integrated_bg}>
					<CaseTestimonials />
					<JoinInstitutionsV2
						outerContainerClassName={styles.outerContainerClassName}
					/>
				</div>
			</Margins>
		</PageLayout>
	);
}

export const pageQuery = graphql`
	{
		caseStudies: allNodeCaseStudy(sort: { order: DESC, fields: created }) {
			nodes {
				title
				path {
					alias
				}
				body {
					summary
				}
				created(formatString: "MMMM DD, YYYY")
				relationships {
					field_feature_image {
						relationships {
							field_media_image {
								localFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
						}
						field_media_image {
							alt
						}
					}
				}
			}
		}
	}
`;
